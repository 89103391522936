<template>
    <Row type="flex" justify="end" >
        <Col span="12">
       
             <Form  :label-width="100">
                   <FormItem label="冷轧数据文件">
                <Upload 
              action="http://115.159.193.114:9090/uploadExcel"
              :on-success="upfileSuccess"
              accept=".xlsx"
              >
            <Button  type="primary" icon="ios-cloud-upload-outline">选择文件</Button>
          </Upload>
            </FormItem>
            <FormItem label="卷板数据文件">
                <Upload 
                    action="http://115.159.193.114:9090/uploadJbFile"
                    :on-success="upfileSuccess"
                    accept=".xlsx"
                > 
                <Button  type="primary" icon="ios-cloud-upload-outline">选择文件</Button>
                </Upload>
            </FormItem>
             </Form>
        
        </Col>
        <Col span="12"></Col>
    </Row>
</template>

<script>
export default {
  name: "uploadFilePage",
  data() {
    return {
      pageIndex:1,
      switchValue: true
    };
  },
  mounted() {
   
 
  },created(){

  },methods:{
    upfileSuccess(response, file, fileList){
      let data = response;
      if(response.code===200){
            this.$Message.success('操作成功');
      }
      if(response.code==400){
         this.$Message.error(response.msg);
      }
      if(response.code===500){
        this.$Message.error("系统错误");
      }
       
    }
    
     
  }
};
</script>

<style scoped>
    .upload{
        border: 1px solid #aaaaaa;
    }
</style>